/* njdi.scss */

/* Note: Bulma breakpoints are set at mobile (up to 768px), tablet (769px and up), desktop (1024px and up), widescreen (1216px and up), and fullhd (1408px and up). */


$tan: #D1B547;
$brown1: #A89A75;
$brown2: #A29057;
$grey: #B6AB95;
$grey2: #444422;
$gold: #C39F4B;
$verylightyellow: #fefeee;
$slightlydarkeryellow: #efefdd;

/* imported from /scss/partials/_layout-colors.scss */
$primary: #D1B547;
$secondary: #A89A75;
$section-grey: #B6AB95;
$section-grey-accent: #A29057;
$background-grey: #B6AB95;
$header-grey: #B6AB95;
$default-input: #B6AB95;
$placeholder: #B6AB95;
$fade-grey: #B6AB95;
$title-grey: #D1B547;




.hide {display:none;}
.pageloader {background-color:$tan !important;}
.infraloader {background-color:$brown1 !important;}
strong {color:$grey2;}
#backtotop a {background-color:$gold;}
#backtotop a:hover {background-color:$tan !important;}
.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {color:$grey2;}
.button {color:$grey2;border-color:$brown2;}
.button i {color:$grey2;}
.button:hover i {color:$gold;}


.section .title-divider {background:$gold;}
.section .centered-title h2 {color:$grey2;}
.section .centered-title p.subheading {color:$grey;}
.section h3.detailed-feature-subtitle {color:$grey2}
.section h2.dark-text {color:$gold !important;}
.section .section-feature-description {color:$grey2;}
.section h3.subtitle {text-transform: uppercase; /*font-family: 'Nexa Light';font-weight: 600; */ font-weight: bold;font-family: 'Nexa Bold', sans-serif;}

.section.section-feature {background-color: $verylightyellow}
.section.section-feature .centered-title p.subheading {color:$grey2;max-width:85%;}
.section.section-feature .centered-title p.subheading strong {padding:4px 5px 1px 5px !important;background-color:$tan;color:#fff;}
.section.section-feature a {color:$brown2;}
.section.section-feature a:hover {color:$gold !important;}

.section.section-feature-grey {background-color: $slightlydarkeryellow;}
.section.section-feature-grey .centered-title h2 {color:$grey2;}
.section.section-feature-grey .centered-title p.subheading {color:$grey2;max-width:85%;}
.section.section-feature-grey .centered-title p.subheading strong {padding:4px 5px 1px 5px !important;background-color:$tan;color:#fff;}
.section.section-feature-grey .centered-title p.subheading a {color:$brown2;}
.section.section-feature-grey .centered-title p.subheading a:hover {color:$gold !important;}

.section.section-feature-dark {background-color:$tan;}
.section.section-feature-dark h2 {color:$verylightyellow;}
.section.section-feature-dark .title-divider {background-color:$slightlydarkeryellow;}
.section.section-feature-dark p.subheading {margin:0;padding:0;color:$verylightyellow;}
.section.section-feature-dark p.sub-subheading {margin:0;padding:1.5em 0 0 0;text-align:left;font-size:1.1em;}
.section.section-feature-dark p, .section.section-feature-dark ul li, section.section-feature-dark strong {color:$verylightyellow;}
.section.section-feature-dark a, .section.section-feature-dark p.subheading a {color:$slightlydarkeryellow;}
.section.section-feature-dark a:hover {color: $verylightyellow;}
.section.section-feature-dark .column {display:flex;}
.section.section-feature-dark .column h3 {font-weight: bold;font-family: 'Nexa Bold', sans-serif;}
.section.section-feature-dark .flex-card p, .section.section-feature-dark .flex-card ul li, .section.section-feature-dark .flex-card strong {color:$grey2;}
.section.section-feature-dark .flex-card a {font-weight:bold;color:$gold;}
.section.section-feature-dark .flex-card a:hover {color:$brown2;}

.centered-title p.subheading {margin-bottom:2em;max-width:200vh;}
.centered-title p.subheading a {color:$tan;}
.centered-title p.subheading a:hover {color:$brown1;}

.button.is-primary {background:$tan;}
.button.is-primary:hover {background:$brown1;}

.navbar-wrapper .navbar-brand .navbar-presents {display:none;}
.navbar-wrapper.navbar-faded .navbar-brand .navbar-presents {display:block;}
.navbar-wrapper.navbar-faded #navbar-donate {background:$tan;}
.navbar-wrapper #navbar-donate i {color:#fff;}
.navbar-wrapper #navbar-donate:hover i {color:$gold;}
.navbar-wrapper.navbar-faded #navbar-donate i {color:#fff;}
.navbar-wrapper.navbar-faded #navbar-donate:hover {color:#fff;}
.navbar.navbar-light.is-dark-mobile {background-color:$gold;}
.navbar.navbar-light.is-dark-mobile .navbar-menu {background-color:$brown2;}
.navbar.navbar-light.is-dark-mobile .navbar-menu a {font-weight:bold;}
.navbar.navbar-light.is-dark-mobile .navbar-menu a.button:hover {color:$brown2 !important;}

 
.hero-body h1 {padding-bottom:0.5em !important;text-shadow: 1px 2px 6px rgba(0,0,0,0.6);font-weight: bold;font-family: 'Nexa Bold', sans-serif;}
.hero-body h2 {padding-right:1em;padding-left:1em;text-shadow: 0px 1px 4px rgba(0,0,0,0.9);line-height:1.4em;font-size:1.8em !important;font-weight: bold;font-family: 'Nexa Bold', sans-serif;}
.hero-body h2 strong {padding:1px 4px;color:#fff;background-color:$brown2;white-space:nowrap;}
.hero-body #goToSearch {margin-top:2em;box-shadow: 1px 3px 2px rgba(0,0,0,0.3);}
.hero-foot .partner-tabs ul li {margin:0 0.2em 3em 0.2em;font-size:1.2em;font-weight:bold;color:#fff;text-shadow: 0px 1px 3px rgba(0,0,0,0.6);}
.hero-foot .partner-tabs ul li a {padding:0;border:0;}
.hero-foot .partner-tabs ul li img.rtr-logo {height:60px !important;}
.hero-foot .partner-tabs ul li img.partner-logo {height:50px !important;}
@media only screen and (max-width: 1024px) {
	.hero-body h2 {font-size:1.4em !important;}
	.hero-foot .partner-tabs ul {}
	.hero-foot .partner-tabs ul li {font-size:1em;}
	.hero-foot .partner-tabs ul li img.rtr-logo {height:30px !important;}
	.hero-foot .partner-tabs ul li img.partner-logo {height:20px !important;}
}


#search h2.title {margin-bottom:2em !important;font-weight: bold;font-family: 'Nexa Bold', sans-serif;}
#search #errors {background-color:$tan;}
#search #errors p i {margin-right:0.5em;}
#search input {color:$grey2;}
#search input.search-year {max-width:60px;}
#search .button {color:$grey2;}
#search .select select {margin-right:0.5em;float:left;}
#search .note {font-weight:normal;color:$grey2;}
#search p.content {margin-top:30px;font-size:0.8em;}
#search #tips-and-tricks, #search #tips-and-tricks h3, #search #tips-and-tricks ul li, #search #tips-and-tricks strong {color:$grey2 !important;}
#search #tips-and-tricks h3 {margin-left:10px;text-transform:none !important;}
@media only screen and (max-width: 1024px) {
	#search #tips-and-tricks {font-size:0.8em;}
}
@media only screen and (min-width: 1024px) {
	#search #tips-and-tricks {font-size:0.9em;}
}
@media only screen and (min-width: 1216px) {
	#search #tips-and-tricks {font-size:0.9em;}
}

.loading #spinner {background:url(../images/loading_spinner.svg) no-repeat center center;background-size: contain;min-height:300px;}
#results.loading p, #results.loading table {display:none;}

#results .table.responsive-table tr th {background:$tan; color:$grey2 !important;}
#results .table.responsive-table tr:first-child {border-top:1px solid #ddd;background:transparent !important;color: #333 !important;}

@media only screen and (max-width: 1024px) {
	#results .table tr th {padding:5px;font-size:0.8em;}
	#results .table tr td {padding:5px;font-size:0.8em;}
}
@media only screen and (min-width: 1024px) {
	#results .table tr th {padding:8px;white-space:nowrap;font-size:0.9em;}
	#results .table tr td {padding:5px 8px;white-space:nowrap;font-size:0.9em;} 
}
@media only screen and (min-width: 1216px) {
	#results .table tr th {white-space:nowrap;}
	#results .table tr td {white-space:nowrap;}
}
#results .table.responsive-table tbody tr:hover {background-color:$grey-white !important;}
#results .table.responsive-table tr td.no-close-results {padding:20px;}
#results #noResults {text-align:center;font-size:1.4em !important;}

#browse .columns {margin-bottom:60px;}
#browse i.fa {font-size:5em;color:$tan;}
#browse .section-feature-description p {margin-bottom:1em;}
#browse .section-feature-description ul {margin:0;padding:0;list-style-type:none;}
#browse .section-feature-description ul li {margin:0 0.5em 0 0;padding:0;list-style-type:none;font-weight:bold;}
#browse .section-feature-description ul li a {font-weight:normal;}
#browse .has-text-right .section-feature-description ul {text-align:right;float:right;}
#browse #soundex {margin-top:1.5em;margin-bottom:1.5em;padding:1.4em 2em 1em 2em;background-color:$tan;color:$grey2;}
#browse #soundex h3 {margin-bottom:1em;}
#browse #soundex #soundexForm {padding-top:1em;font-weight:bold;}
#browse #soundex #soundexForm #chName {margin-left:1em;margin-right:1em;font-weight:bold;font-size:1em;max-width:100px;}
#browse #soundex #soundexForm #bSoundex {background-color:#fff;color:$brown2;}
#browse #soundex #soundexForm #bSoundex i, #browse #soundex #soundexForm #bSoundex i:hover {font-size:1em !important;color:$brown2;}
#browse #soundex #soundexForm #chSoundex {margin-left:1em;font-weight:bold;font-size:1em;max-width:100px;}
#browse ul li.note {font-size:0.8em;}
@media only screen and (max-width: 1024px) {
	#browse #soundex {padding:1em 1em 0.5em 1em;}
	#browse #soundex h3 {font-size:1em;}
	#browse #soundex #soundexForm #chName {margin-left:0.5em;margin-right:0.5em;;max-width:80px;}
	#browse #soundex #soundexForm #chName, #browse #soundex #soundexForm #bSoundex, #browse #soundex #soundexForm #bSoundex i, #browse #soundex #soundexForm #bSoundex i:hover {font-size:0.8em !important;}
	#browse .content-wrapper h3.subtitle {font-size:1em;}
	#browse .content-wrapper p, #browse .content-wrapper ul li {font-size:0.8em;}
}


#newsletter label {font-weight:bold;}
#newsletter label.checkbox {font-weight:normal;}
#newsletter p.note {font-size:0.8em;}
#newsletter ul {margin-top:1em;padding-left:20px;}
#newsletter .display_archive {margin-top:1em;font-size:0.8em;max-height:275px;overflow-y:scroll;}
#newsletter .campaign {margin-bottom:0.5em;}
#newsletter .recent-newsletters .display_archive::-webkit-scrollbar {-webkit-appearance: none;width: 7px;}
#newsletter .recent-newsletters .display_archive::-webkit-scrollbar-thumb {border-radius: 4px;background-color: rgba(0,0,0,.5);-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);}
#newsletter .campaign a {color:$brown2;}
#newsletter .campaign a:hover {color:$gold !important;}

#donate h2 i {font-size:0.7em !important;}
#donate p.subheading {margin-bottom:0;padding-bottom:0;}
#donate a {color:$gold;}
#donate a:hover {color:$brown2 !important;}
#donate a.button {color:$grey2;background-color:#fff;}
#donate a.button:hover {color:$grey2 !important;background-color:#fff;}

#dark-footer {background-color:$grey2;}
.footer #twitter-widget-holder {max-height:340px;overflow-y:scroll;}
.footer a {color:$verylightyellow;}
.footer a:hover {color:$brown1;}
.footer ul li {margin-bottom:1.4em;line-height:1.4em !important;}
.footer ul li a {color:$slightlydarkeryellow !important;}
.footer ul li a:hover {color:$brown1 !important;}